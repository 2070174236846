<dep-main-header></dep-main-header>

<main class="main">
    <!-- Page header -->
    <dep-page-header-text-image class="page-header-wrapper"
        [headerTitle]="'Inscription à la communauté des startups deeptech'" [isInscriptionForm]="true">
        <!-- Breadcrumb -->
        <dep-breadcrumb [breadcrumbLabel]="'Fil d\'ariane'" breadcrumb>
            <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
            <dep-breadcrumb-item [itemRouterLink]="'/communaute-startups-deeptech-france/.'" *appBreadcrumbItem>
                Communauté d'entrepreneurs</dep-breadcrumb-item>
            <dep-breadcrumb-item *appBreadcrumbItem>Inscription à la communauté des startups deeptech
            </dep-breadcrumb-item>
        </dep-breadcrumb>
        <!-- Text -->
        <ng-container slot="text">
            <p>
                Remplissez le formulaire ci-dessous pour intégrer la 1ère communauté d'entrepreneurs deeptech en
                France.<br />
                <br />
                Si votre startup remplit les critères pour être identifiée comme deeptech, vous recevrez dans un délai
                de 2 semaines maximum un kit de bienvenue et une invitation à la session mensuelle d'onboarding à la
                communauté.<br />
                <br />
                En rejoignant la communauté, votre startup est automatiquement référencée dans <a
                    style="text-decoration: underline;" target="_blank" rel="noopener noreferrer"
                    href="https://observatoire.lesdeeptech.fr/">l'Observatoire</a> deeptech.
            </p>
        </ng-container>
    </dep-page-header-text-image>

    <!-- Formulaire d'inscription -->
    <dep-page-section>
        <div class="form__grid-container">
            <div class="lg-grid-col-7 md-grid-col-6 sm-grid-col-4">
                <form [formGroup]="membershipForm" (ngSubmit)="onSubmit()">
                    <!-- Votre entreprise -->
                    <dep-form-section>
                        <div class="entreprise__section-container">
                            <div class="entreprise__section-form">
                                <div class="lg-grid-col-8">
                                    <h2 class="form__title">Votre startup</h2>
                                </div>
                                <!-- Entreprise -->
                                <div class="lg-grid-col-4 md-grid-col-2 sm-grid-col-2 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="startup.dirty && startup.errors?.required">
                                    <label class="ds-input-group__label">Nom de l'entreprise</label>
                                    <input formControlName="startup" type="text" name="startup" 
                                        class="ds-input-group__input form__input" placeholder="MaStartup" />
                                    <ng-container *ngIf="startup.dirty && startup.errors?.required">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner le nom de votre entreprise
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- Année de création  -->
                                <div class="lg-grid-col-4 md-grid-col-2 sm-grid-col-2 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="yearOfCreation.dirty && yearOfCreation.errors">
                                    <label class="ds-input-group__label">Année de création</label>
                                    <input formControlName="yearOfCreation" type="text" pattern="\d*" maxlength="4" 
                                        name="yearOfCreation"  class="ds-input-group__input form__input"
                                        placeholder="Ex: 2013" />
                                    <ng-container *ngIf="yearOfCreation.dirty && yearOfCreation.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner l'année de création
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- Code postal  -->
                                <div class="lg-grid-col-8 md-grid-col-2 sm-grid-col-2 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="postalCode.dirty && postalCode.errors">
                                    <label class="ds-input-group__label">Code postal</label>
                                    <input formControlName="postalCode" type="text" name="postalCode"
                                        class="ds-input-group__input form__input" placeholder="Ex: 91320" />
                                    <ng-container *ngIf="postalCode.dirty && postalCode.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner le Code postal
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- SIREN -->
                                <div class="lg-grid-col-4 md-grid-col-2 sm-grid-col-2 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="siren.dirty && siren.errors">
                                    <label class="ds-input-group__label">SIREN</label>
                                    <input formControlName="siren" name="siren" type="text" 
                                         class="ds-input-group__input form__input"  placeholder="XXX XXX XXX" />
                                    <ng-container *ngIf="siren.dirty && siren.value.length === 0 && siren.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner le numéro de SIREN de votre entreprise
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="siren.dirty && siren.value.length !== 0 && siren.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner un numéro de SIREN à 9 chiffres
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- Site web -->
                                <div class="lg-grid-col-4 md-grid-col-2 sm-grid-col-2 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="website.dirty && website.errors">
                                    <label class="ds-input-group__label">Site web</label>
                                    <input formControlName="website" name="website" type="url"
                                        class="ds-input-group__input form__input" placeholder="Ex : www.lesdeeptech.fr" />
                                    <ng-container *ngIf="website.dirty && website.value.length === 0 && website.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner votre site web
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="website.dirty && website.value.length !== 0 && website.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner un site web valide (https://exemple.com)
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- Client -->
                                <div class="lg-grid-col-4 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="isClient.dirty && isClient.errors?.required">
                                    <label class="ds-input-group__label">Êtes-vous client(e) de Bpifrance ?</label>
                                    <div class="page-header__switch">
                                        <label class="page-header__switch-option">
                                            <input formControlName="isClient" type="radio" id="isClient" 
                                                name="isClient" [value]="true" >
                                            <span>Oui</span>
                                        </label>

                                        <label class="page-header__switch-option">
                                            <input formControlName="isClient" type="radio" id="isntClient" 
                                                name="isClient" [value]="false" >
                                            <span>Non</span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <img src="/assets/images/inscription.png"
                                class="lg-grid-col-5 md-grid-col-2 sm-grid-col-4 illustration" alt="illustration">
                        </div>
                    </dep-form-section>
                    <!-- FIN de Votre entreprise -->

                    <!-- Votre startup et la deeptech -->
                    <dep-form-section>
                        <div class="entreprise__section-container">
                            <div class="entreprise__section-form-two">
                                <div class="lg-grid-col-8">
                                    <h2 class="form__title">Votre startup et la deeptech</h2>
                                </div>

                                <!--secteur d'activité -->
                                <div class="lg-grid-col-4 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="techField.dirty && techField.errors?.required">
                                    <label class="ds-input-group__label">Secteur(s) d'activité</label>
                                    <div
                                        class="ds-input-group__select ds-input-group__select--with-icon form__input sector_select">
                                        <select formControlName="techField" name="techField" type="text" 
                                            class="ds-input-group__select ds-input-group__select--with-icon ">
                                            <option value="" disabled>Biotech, Medtech, Energie…</option>
                                            <option *ngFor="let sector of sectors; let i = index" [value]="sector">
                                                {{sector}}
                                            </option>
                                        </select>
                                    </div>
                                    <ng-container *ngIf="techField.dirty && techField.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez choisir votre secteur d'activité
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- Que faites-vous ?-->
                                <div class="lg-grid-col-7 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="activity.dirty && activity.errors?.required">
                                    <label class="ds-input-group__label">Que faites-vous ?</label>
                                    <textarea formControlName="activity" name="activity" maxlength="300" 
                                        class="ds-input-group__textarea textarea-size"
                                        placeholder="Décrivez en quelques mots l'activité de votre entreprise (300 caractères max) ">
                                    </textarea>
                                    <ng-container *ngIf="activity.dirty && activity.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez décrire l'activité de votre entreprise en 300 caractères max
                                        </div>
                                    </ng-container>
                                </div>

                                <!--Quels sont vos liens actuels et passés avec la recherche (publique ou privée)  ?-->
                                <div class="lg-grid-col-7 md-grid-col-8 sm-grid-col-4 ds-input-group"
                                    [class.ds-input-group--error]="researchLink.dirty && researchLink.errors?.required">
                                    <label class="ds-input-group__label long-label">Quels sont vos liens actuels et
                                        passés avec la recherche (publique ou privée) ? *</label>
                                    <textarea formControlName="researchLink" name="researchLink" maxlength="300"
                                        required class="ds-input-group__textarea textarea-size"
                                        placeholder="Précisez si nécessaire comment a été développé la technologie de votre startup (laboratoire public, privé, développée en interne), les profils de votre équipe R&D vos éventuelles relations avec des laboratoires">
                                    </textarea>
                                    <ng-container *ngIf="researchLink.dirty && researchLink.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez décrire vos liens actuels et passés avec la recherche (publique ou
                                            privée)
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="technology-grid">
                                    <!-- Votre technologie a-t-elle bénéficié d'un transfert de technologie -->
                                    <div class="lg-grid-col-4 md-grid-col-8 sm-grid-col-4 ds-input-group"
                                        [class.ds-input-group--error]="isOTT.dirty && isOTT.errors?.required">
                                        <label class="ds-input-group__label" style="padding-bottom: 30px;">Votre
                                            technologie a-t-elle bénéficié d'un <br />transfert de technologie ?
                                            *</label>
                                        <div class="page-header__switch">
                                            <label class="page-header__switch-option">
                                                <input formControlName="isOTT" type="radio" id="isOTT" name="isOTT" 
                                                    [value]="true" (click)="itTTO(true)" >
                                                <span>Oui</span>
                                            </label>

                                            <label class="page-header__switch-option">
                                                <input formControlName="isOTT" type="radio" id="isntOTT" name="isOTT" 
                                                    [value]="false" (click)="itTTO(false)" >
                                                <span class="concours-option">Non</span>
                                            </label>
                                        </div>
                                    </div>
                                    <!--Si oui, avec quel organisme ? -->
                                    <div class="lg-grid-col-4 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
                                        [class.ds-input-group--error]="tto.dirty && tto.errors?.required"
                                        style="margin-top: 15px;">
                                        <label class="ds-input-group__label">Si oui, avec quel organisme ?</label>
                                        <div
                                            class="ds-input-group__select ds-input-group__select--with-icon form__input">
                                            <select formControlName="tto" id="tto" name="tto" type="text" 
                                                class="ds-input-group__select ds-input-group__select--with-icon">
                                                <option value="" disabled>Sélectionnez un organisme</option>
                                                <option *ngFor="let tech of organizationOptions; let i = index"
                                                    [value]="organizationOptions[i]">
                                                    {{organizationOptions[i]}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="patent-grid">
                                    <!-- Avez-vous déposez un brevet? -->
                                    <div class="lg-grid-col-4 md-grid-col-8 sm-grid-col-4 ds-input-group"
                                        [class.ds-input-group--error]="isPatent.dirty && isPatent.errors?.required">
                                        <label class="ds-input-group__label" style="padding-bottom: 30px;">Avez-vous
                                            déposez un brevet ? *</label>
                                        <div class="page-header__switch">
                                            <label class="page-header__switch-option">
                                                <input formControlName="isPatent" type="radio" id="isPatent" name="isPatent" 
                                                    [value]="true" (click)="itPatent(true)" >
                                                <span>Oui</span>
                                            </label>

                                            <label class="page-header__switch-option">
                                                <input formControlName="isPatent" type="radio" id="isntPatent" name="isPatent" 
                                                    [value]="false" (click)="itPatent(false)" >
                                                <span class="concours-option">Non</span>
                                            </label>
                                        </div>
                                    </div>
                                    <!--Si oui, avec quel organisme ? -->
                                    <div class="lg-grid-col-4 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
                                        style="margin-top: 15px;"
                                        [class.ds-input-group--error]="patent.dirty && patent.errors?.required">
                                        <label class="ds-input-group__label">Si oui, avec quel organisme ?</label>
                                        <div
                                            class="ds-input-group__select ds-input-group__select--with-icon form__input sector_select">
                                            <select formControlName="patent" id="patent" name="patent" type="text" 
                                                class="ds-input-group__select ds-input-group__select--with-icon">
                                                <option value="" disabled>Sélectionnez un organisme</option>
                                                <option *ngFor="let tech of organizationOptions; let i = index"
                                                    [value]="organizationOptions[i]">
                                                    {{organizationOptions[i]}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <!--Quelle est l'intensité technologique de votre startup ?-->
                                <div class="lg-grid-col-7 md-grid-col-8 sm-grid-col-4 ds-input-group"
                                    [class.ds-input-group--error]="technology.dirty && technology.errors?.required">
                                    <label class="ds-input-group__label long-label">Quelle est l'intensité technologique
                                        de votre startup ? *</label>
                                    <textarea formControlName="technology" name="technology" maxlength="300"
                                        class="ds-input-group__textarea textarea-size"
                                        placeholder="Précisez l'avantage technologique de votre startup, sa protection industrielle et si nécessaire ses grands points différenciants vis-à-vis de concurrents">
                                    </textarea>
                                    <ng-container *ngIf="technology.dirty && technology.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez décrire l'intensité technologique de votre startup
                                        </div>
                                    </ng-container>
                                </div>

                                <!--Quelles sont selon vous les étapes d'accès au marché pour votre technologie-->
                                <div class="lg-grid-col-7 md-grid-col-8 sm-grid-col-4 ds-input-group"
                                    [class.ds-input-group--error]="addedValue.dirty && addedValue.errors?.required">
                                    <label class="ds-input-group__label long-label">Quelles sont selon vous les étapes
                                        d'accès au marché pour votre technologie ? *</label>
                                    <textarea formControlName="addedValue" name="addedValue" maxlength="300"
                                        class="ds-input-group__textarea textarea-size"
                                        placeholder="Détaillez le temps nécessaire à la R&D, les évolutions réglementaires, l'acceptation populaire,ect">
                                    </textarea>
                                    <ng-container *ngIf="addedValue.dirty && addedValue.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez décrire le temps nécessaire à la R&D, les évolutions
                                            réglementaires, l'acceptation populaire,ect
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="lg-grid-col-4 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required other_select"
                                    [class.ds-input-group--error]="cks.dirty && cks.errors?.required"
                                    style="margin-top: 15px;">
                                    <label class="ds-input-group__label">Comment avez-vous connu la communauté ?</label>
                                    <div class="ds-input-group__select ds-input-group__select--with-icon form__input bigger_select">
                                        <select formControlName="cks" id="cks" name="cks"
                                            (change)="enableOthersTextArea()"
                                            class="ds-input-group__select ds-input-group__select--with-icon"  >
                                            <option value="" selected disabled>Sélectionner</option>
                                            <option *ngFor="let source of communityKnowledgeSources; let i = index"
                                                [value]="source">
                                                {{source}}
                                            </option>
                                        </select>
                                        <ng-container *ngIf="cks.dirty && cks.errors">
                                            <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                            <div class="ds-input-group__feedback">
                                                Veuillez sélectionner une option
                                            </div>
                                        </ng-container>
                                    </div>
                                    
                                </div>

                                <div class="lg-grid-col-7 md-grid-col-8 sm-grid-col-4 ds-input-group"
                                    [class.ds-input-group--error]="otherCommunity.dirty && otherCommunity.errors?.required">
                                    <label class="ds-input-group__label long-label">Autre *</label>
                                    <textarea formControlName="otherCommunity" name="otherCommunity" id="otherCommunity" maxlength="300"
                                        class="ds-input-group__textarea textarea-size" placeholder="">
                                    </textarea>
                                    <ng-container *ngIf="otherCommunity.dirty && otherCommunity.errors">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez préciser comment avez vous connu la communauté
                                        </div>
                                    </ng-container>
                                </div>


                            </div>
                        </div>
                    </dep-form-section>
                    <!-- Informations personnelles -->
                    <dep-form-section>
                        <div class="entreprise__section-container">
                            <div class="entreprise__section-form">
                                <div class="lg-grid-col-8">
                                    <h2 class="form__title">Informations personnelles</h2>
                                </div>
                                <div class="lg-grid-col-8 md-grid-col-8 sm-grid-col-4">
                                    <p>Indiquez nous vos coordonées afin que nous puissions vous contacter en cas de
                                        questions.
                                        Elles ne seront nullement rendues publiques.</p>
                                </div>

                                <!-- Nom -->
                                <div class="lg-grid-col-4 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="lastname.dirty && lastname.errors?.required">
                                    <label class="ds-input-group__label">Nom</label>
                                    <input formControlName="lastname" type="text" name="lastname"
                                        class="ds-input-group__input form__input" placeholder="Wick" />
                                    <ng-container *ngIf="lastname.dirty && lastname.errors?.required">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner votre nom
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- Prénom -->
                                <div class="lg-grid-col-4 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="firstname.dirty && firstname.errors?.required">
                                    <label class="ds-input-group__label">Prénom</label>
                                    <input formControlName="firstname" type="text" name="firstname"
                                        class="ds-input-group__input form__input" placeholder="John" />
                                    <ng-container *ngIf="firstname.dirty && firstname.errors?.required">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner votre prénom
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- Poste -->
                                <div class="lg-grid-col-8 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="job.dirty && job.errors?.required">
                                    <label class="ds-input-group__label">Poste</label>
                                    <input formControlName="job" type="text" name="job"
                                        class="ds-input-group__input form__input" placeholder="CEO,associé..." />
                                    <ng-container *ngIf="job.dirty && job.errors?.required">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner votre poste
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- Email -->
                                <div class="lg-grid-col-4 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--required"
                                    [class.ds-input-group--error]="email.dirty && (email.errors || emailFound)">
                                    <label class="ds-input-group__label">Adresse email</label>
                                    <input formControlName="email" type="email" name="email"
                                        class="ds-input-group__input form__input" placeholder="mon-email@exemple.fr" />
                                    <ng-container *ngIf="email.dirty &&  emailFound">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            {{messageEmailFound}}
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="email.dirty && email.value.length === 0 && email.errors && !emailFound">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner votre adresse email
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="email.dirty && email.value.length !== 0 && email.errors && !emailFound">
                                        <i class="ds-input-group__icon fa fa-exclamation-triangle"></i>
                                        <div class="ds-input-group__feedback">
                                            Veuillez renseigner une adresse email valide (mon-email@exemple.fr)
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- Téléphone -->
                                <div
                                    class="lg-grid-col-4 md-grid-col-8 sm-grid-col-4 ds-input-group ds-input-group--phone">
                                    <label for="phone" class="ds-input-group__label">Téléphone</label>
                                    <input formControlName="phone" id="phone" name="phone" type="tel"
                                        class="ds-input-group__input form__input" />
                                </div>

                            </div>
                        </div>

                    </dep-form-section>

                    <!-- FIN de vos Infos personnelles -->
                    <!-- Confidentialité -->
                    <dep-form-section>
                        <div class="lg-grid-col-12 md-grid-col-8 sm-grid-col-4">
                            <div class="ds-checkbox-group">
                                <input formControlName="newsletter" type="checkbox" id="newsletter" class="ds-checkbox-group__checkbox-input"
                                    name="newsletter" />
                                <label for="newsletter" class="ds-checkbox-group__checkbox-label">
                                    Je souhaite recevoir des informations relatives à la deeptech de la part de
                                    Bpifrance
                                </label>
                            </div>
                            <br />
                        </div>
                    </dep-form-section>
                    <!-- Fin de Confidentialité -->

                    <!--recapcha -->
                    <div id="captcha_element2" class="g-recaptcha" data-callback="getResponseCaptcha"></div>
                    <div *ngIf="captchaError" class="ds-input-group--error">{{'veuillez cocher le captcha'}}</div>
                    <!-- FIN recapcha-->

                    <!-- CGU -->
                    <dep-form-section>
                        <div class="lg-grid-col-6 md-grid-col-8 sm-grid-col-4">
                            <p class="cgu__text">Les données à caractère personnel traitées sont destinées à Bpifrance
                                et pourront également être communiquées
                                à ses prestataires, aux autres Sociétés et Services du groupe Bpifrance et utilisées par
                                ceux-ci pour tout ou partie des finalités définies ci-dessus.
                                <br /><br />
                                Bpifrance, ou toute autre entité du Groupe Bpifrance, conservera les données à caractère
                                personnel conformément aux durées de prescription légales et réglementaires françaises
                                et européennes.
                                <br /><br />
                                Conformément à la Réglementation applicable, et sous réserve des conditions prévues par
                                cette règlementation pour l'exercice de ces droits, toute personne dont les données
                                à caractère personnel sont collectées pour les traitements visés ci-dessus, bénéficie
                                d'un droit d'accès, de rectification, de suppression, ainsi que du droit à la
                                portabilité de ses données à caractère personnel.
                                Toute personne dont les données à caractère personnel sont collectées dispose également
                                du droit de demander la limitation des traitements qui la concerne et de s'opposer à
                                recevoir de la prospection commerciale ou à faire l'objet de profilage lié à la
                                prospection commerciale.
                                Dans certains cas, elle peut pour des raisons tenant à sa situation particulière,
                                s'opposer au traitement de ses données,
                                y compris au profilage. Toute personne dispose également, conformément à la loi
                                Informatique et Libertés, du droit d'organiser le sort de ses données à caractère
                                personnel post-mortem.
                                <br /><br />
                                Ces droits peuvent être exercés par l'envoi d'un courrier, soit par la poste à
                                Bpifrance, DCCP, Délégué à la protection des données,
                                au 27-31 avenue du Général Leclerc, 94710 Maisons-Alfort Cedex, soit par email, à
                                <a style="text-decoration: underline;"
                                    href="mailto:donneespersonnelles@bpifrance.fr">donneespersonnelles@bpifrance.fr</a>.
                                <br /><br />
                                Enfin, les personnes disposent du droit d'introduire une réclamation auprès de la
                                Commission Nationale de l'Informatique et des Libertés (CNIL).
                                <br /><br />
                                Pour en savoir plus sur la gestion de vos données à caractère personnel et vos droit à
                                cet égard, reportez-vous aux
                                <a style="text-decoration: underline;"
                                   href="./assets/docs/CGU lesdeeptech.fr site vitrine v2.pdf" target="_blank"  rel="noopener noreferrer">conditions générales
                                    d'utilisation </a>.
                            </p>
                            <br />
                        </div>
                        <dep-button class="lg-grid-col-7 md-grid-col-6 sm-grid-col-4 position-send-button">Envoyer
                        </dep-button>
                    </dep-form-section>
                    <!-- FIN cgu -->
                </form>
            </div>
        </div>
    </dep-page-section>
    <!-- FIN de Formulaire d'adhésion -->


</main>
<!-- TOASTER : error -->
<dep-toaster [status]="'error'" [toasterTitle]="'Une erreur s\'est produite'" [toasterText]="messageEmailFound"
    [show]="toasterFailure" (closeToaster)="closeToaster()">
</dep-toaster>

<!-- TOASTER : success -->
<dep-toaster [status]="'success'" [toasterTitle]="'Votre demande a bien été envoyée'"
    [toasterText]="'Merci pour cet envoi, nous vous recontacterons dans les plus brefs délais.'" [show]="toasterSuccess"
    (closeToaster)="closeToaster()">
</dep-toaster>
<dep-main-footer></dep-main-footer>
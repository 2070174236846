<dep-main-header class="main-header"></dep-main-header>

<main class="main" *ngIf="actuality">
  <!-- Sticky header -->
  <dep-article-sticky-header [articleTitle]="actuality?.title" [articleCategories]="actuality?.tags"
    [buttonIcon]="'icon-share'" [buttonText]="'Partager'" (buttonAction)="copyUrl()" [buttonMessage]="'Lien copié !'"
    [showButtonMessage]="clipboardCopySuccess"></dep-article-sticky-header>

  <!-- Page header -->
  <dep-page-header-image class="page-header-wrapper"
    [headerImage]="actuality?.bannerImageUrl ? actuality?.bannerImageUrl : actuality?.imageUrl" [altImage]="actuality?.altImage">
    <!-- Article header content -->
    <dep-article-header [articleTitle]="actuality?.title"
      [articleDate]="actuality?.publishDate | date :'d MMMM yyy' | titlecase" 
      [articleDateTime]="actuality?.publishDate | date :'d MMMM yyy'"
      [articleReadingTime]="actuality?.readingTime"
      [buttonIcon]="'icon-share'"
      [buttonText]="'Partager'"
      [isPopover]="true" content>
      <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
        <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
        <dep-breadcrumb-item [itemRouterLink]="'/actualites-ressources/.'" *appBreadcrumbItem>Actualités et
          ressources</dep-breadcrumb-item>
        <dep-breadcrumb-item *appBreadcrumbItem>Actualités</dep-breadcrumb-item>
      </dep-breadcrumb>
    </dep-article-header>

    <!-- Themes -->
    <dep-article-categories [articleCategories]="actuality?.tags" subheader></dep-article-categories>
  </dep-page-header-image>

  <!-- Article content -->
  <div class="article-wrapper">
    <ng-container *ngFor="let actualityContent of actuality?.contents">

      <!-- Text -->
      <ng-container *ngIf="actualityContent.textId">
        <p [innerHTML]="actualityContent.paragraph | markdown"></p>
      </ng-container>

      <!-- Quote -->
      <ng-container *ngIf="actualityContent.quoteId">
        <dep-article-quote [quote]="actualityContent.quote" [quoteSource]="actualityContent.authorName">
        </dep-article-quote>
      </ng-container>

      <!-- Related content -->
      <ng-container *ngIf="actualityContent.linkId">
        <dep-article-related-content [relatedContent]="actualityContent.linkTitle"
          [relatedLinkContent]="actualityContent.linkUrl">
        </dep-article-related-content>
      </ng-container>

      <!-- Video -->
      <ng-container *ngIf="actualityContent.videoId && actualityContent.videoUrl">
        <dep-article-video [videoUrl]="actualityContent.videoUrl" [videoType]="'video/mp4'"
          [videoTitle]="actualityContent.videoTitle" [videoSource]="actualityContent.videoSource">
        </dep-article-video>
      </ng-container>

      <!-- YOUTUBE video -->
      <!-- TODO : Allow youtube urls in inputs -->
      <ng-container *ngIf="actualityContent.videoId && !actualityContent.videoUrl && actualityContent.videoSource">
        <dep-article-youtube-video [videoUrl]="actualityContent.videoSource"
          [videoTitle]="actualityContent.videoTitle"></dep-article-youtube-video>
      </ng-container>

      <!-- Images carousel -->
 
      <ng-container *ngIf="actualityContent.imagesId">  
      <div class="data__carrousel">
        <dep-new-carousel #carousel>
          <ng-container  *ngFor="let imageUrl of actualityContent.imagesUrls;let i=index">
          <dep-new-carousel-item  
             [imageSrc]="imageUrl" 
             [imageAlt]="actualityContent.altImages[i]"
              *appCarouselSlide>
            </dep-new-carousel-item>
          </ng-container> 
        </dep-new-carousel>
      </div>
      <br/>
          <!--   case of independant title and title -->
           <p class="image-carousel__slide-title" >{{actualityContent.slideShowTitle}}</p>
           <p class="image-carousel__slide-text" >{{actualityContent.imageSource}}</p>
    </ng-container>

      <!-- Image -->
      <ng-container *ngIf="actualityContent.imageId">
        <dep-article-image-carousel [carouselTitle]="actualityContent.imageTitle"
          [carouselSource]="actualityContent.imageSource">
          <dep-article-image-carousel-item [imageSrc]="actualityContent.imageUrl"
            [imageAlt]="actualityContent.altImage" *appCarouselSlide></dep-article-image-carousel-item>
        </dep-article-image-carousel>
      </ng-container>

      <!-- Document -->
      <ng-container *ngIf="actualityContent.documentId">
        <dep-article-document [fileName]="actualityContent.documentTitle" [fileButtonName]="actualityContent.downloadButtonTitle"
          [fileURL]="actualityContent.documentUrl"></dep-article-document>
      </ng-container>

    <!-- Podcast -->
    <ng-container *ngIf="actualityContent.podcastId">
      <dep-article-podcast [podcast]="actualityContent.iframe"></dep-article-podcast>
    </ng-container>

  </ng-container>

  </div>
</main>

<!-- <aside class="suggestions">
  <dep-page-section-actualities [sectionTheme]="'light'" [sectionTitle]="'Suggestions'"
    [sectionRouterLink]="actualitiesTypes[1].route" [sectionLinkIcon]="actualitiesTypes[1].icon"
    *ngIf="actualitiesTypes[1]">
    <div class="suggestions__list">
      <ng-container *ngFor="let n of [].constructor(3); let i = index">
        <app-actuality-thumbnail-prototype [actualityCategories]="['Biotech', 'Agritech']"
          [actualityImage]="'/assets/images/actualites-ressources/EtudeBiotech.jpg'"
          [actualityTitle]="'Un bio-stimulant pour remplacer les produits chimiques'" [actualityDate]="'15 décembre'"
          [actualityDuration]="'8 minutes'"
          [actualityIntro]="'Obtenir un meilleur rendement et une meilleure qualité des plants sans produit chimique. C’est le défi relevé par MycoPhyto, une solution naturelle qui aide les plantes à se nourrir.'"
          [actualityIndex]="i" [actualityRouterLink]="'/actualite-v2021/.'">
        </app-actuality-thumbnail-prototype>
      </ng-container>
    </div>
  </dep-page-section-actualities>
</aside> -->
<div *ngIf="isOpen">
  <dep-newsletter [newsletter] = "newsletter" (closeNewsletter)='closeNewsletter($event)'></dep-newsletter>
</div>
<dep-scroll-to-top></dep-scroll-to-top>
<dep-main-footer></dep-main-footer>


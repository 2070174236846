<dep-main-header class="main-header"></dep-main-header>

<main class="main" *ngIf="sector">
  <div class="banner">
    <!-- Page header -->
    <dep-page-header-simple class="page-header-wrapper">
      <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
        <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
        <dep-breadcrumb-item *appBreadcrumbItem>{{
          sector.title
          }}</dep-breadcrumb-item>
      </dep-breadcrumb>
      <!-- Page Title -->
      <ng-container pageTitle>{{ sector.title }}</ng-container>
    </dep-page-header-simple>

    <!-- Banner image -->
    <div class="banner__image-wrapper">
      <img [src]="sector.imageUrl" class="banner__image" alt="{{sector.altImage}}" />
    </div>
  </div>

  <!-- Section: description -->
  <dep-page-section-bpi [hideHeader]="true">
    <div class="description">
      <div class="description__text">
        <h2 class="description__title">
          <span>Description</span>
        </h2>
        <p id="descText" [innerHTML]="sector.description| markdown" class="description__truncate"></p>

        <div class="description__btn-plus">
          <button id="btnShowMore" class="ds-btn ds-btn--secondary" area-label="load more" (click)="getAllDescription()">En savoir
            plus</button>
        </div>
      </div>
      <div class="description__figures">
        <ul class="description__figures-list" *ngIf="!selectedSectoralPage">
          <li class="description__figure" *ngIf="sector.keyMetrics[0]?.value">
            <strong>{{ sector.keyMetrics[0]?.value}}</strong><br />
            <span>startups deeptech</span>
          </li>
          <li class="description__figure" *ngIf="sector.keyMetrics[1]?.value">
            <strong>{{ sector.keyMetrics[1]?.value }}</strong><br />
            <span>des startups de l'Observatoire</span>
          </li>
          <li class="description__figure" *ngIf="sector.keyMetrics[2]?.value">
            <strong>{{sector.keyMetrics[2]?.value }}</strong><br />
            <span>levés depuis 2018</span>
          </li>
          <li class="description__figure" *ngIf="sector.keyMetrics[3]?.value">
            <strong>{{ sector.keyMetrics[3]?.value }}</strong><br />
            <span>des montants levés depuis 2018</span>
          </li>
          <li class="description__figure" *ngIf="sector.keyMetrics[4]?.value">
            <strong>{{ sector.keyMetrics[4]?.value}}</strong><br />
            <span>emplois générés</span>
          </li>
          <li class="description__figure" *ngIf="sector.keyMetrics[5]?.value">
            <strong>{{sector.keyMetrics[5]?.value}}</strong><br />
            <span>startups deeptech green</span>
          </li>
        </ul>
        <ul class="description__figures-list" *ngIf="selectedSectoralPage">
          <li class="description__figure">
            <strong>{{selectedSectoralPage.suCount}}</strong><br />
            <span>startups deeptech</span>
          </li>
          <li class="description__figure">
            <strong>{{ selectedSectoralPage.suPercentage }}%</strong><br />
            <span>des startups de l'Observatoire</span>
          </li>
          <li class="description__figure">
            <strong>{{funds}} </strong><br />
            <span>levés sur les 5 dernières années</span>
          </li>
          <li class="description__figure">
            <strong>{{ selectedSectoralPage.fundsPercentage}}% </strong><br />
            <span>des montants levés en deeptech en France</span>
          </li>
          <li class="description__figure">
            <strong>{{ selectedSectoralPage.jobsCount}}</strong><br />
            <span>emplois générés</span>
          </li>
          <li class="description__figure" >
            <strong>{{selectedSectoralPage.jobsPercentage}}%</strong><br />
            <span>des emplois generés en deeptech en France</span>
          </li>
        </ul>

        <div class="description__link-wrapper">
          <a href="https://observatoire.lesdeeptech.fr/dashboard?applyDefaultFilters=true" class="description__link ds-btn ds-btn--secondary">Accéder à
            l’Observatoire</a>
        </div>
      </div>
    </div>
  </dep-page-section-bpi>

  <!-- Section: last offers -->
  <dep-page-section-bpi [sectionRouterLink]="actualitiesTypes[1].route" [sectionLinkLabel]="'Voir tous les articles'"
    [sectionLinkIcon]="actualitiesTypes[1].icon" *ngIf="actualitiesTypes[1] && actualities.length > 0">
    <ng-container slot="title">
      <strong>{{actualitiesTypes[1].name}}</strong>
    </ng-container>

    <!-- List of the last actualities -->
    <dep-actualities-list [highlightFirst]="true" [responsiveDisplay]="'carousel'" [actualities]="actualities"
      *ngIf="actualities.length > 0"></dep-actualities-list>
  </dep-page-section-bpi>

    <!-- Map -->
    <dep-page-section-bpi>
      <ng-container slot="title">
        Découvrez les startups deeptech de <strong>votre territoire</strong>​
      </ng-container>
      <dep-map *ngIf="startupsChecked" [isSector]="true" [hideLegend]="true" [startups]="startups"></dep-map>
    </dep-page-section-bpi>

  <!-- Section: last events -->
  <dep-page-section-bpi [sectionRouterLink]="'/evenements/.'" [sectionLinkLabel]="'Voir plus d’évènements'"
    *ngIf="events.length > 0">
    <ng-container slot="title">
      Les <strong>évènements</strong> à venir
    </ng-container>

    <!-- List of the last events -->
    <dep-events-list class="events" [responsiveDisplay]="'carousel'" [eventsCount]="3" [events]="events">
    </dep-events-list>
  </dep-page-section-bpi>

  <ng-container *ngIf="sector.contents.length > 0 ">
    <!-- Section: quotes -->
    <dep-page-section-bpi [sectionOverflow]="'both'">
      <ng-container slot="title">
        <strong>Témoignages</strong>
      </ng-container>

      <!-- List of the last quotes -->

      <dep-quote-blocks-carousel>
        <ng-container *ngFor="let testimony of sector.contents">
          <dep-quote-block [quoteText]="testimony?.quote" [quoteAuthorName]="testimony?.authorName"
            [quoteAuthorJob]="testimony?.authorRole" [quotePhoto]="testimony?.authorPhotoProfile" [quoteBackground]="
            '/assets/images/actualites-ressources/EtudeAgri.jpg'
          " *appCarouselSlide>
          </dep-quote-block>
        </ng-container>
      </dep-quote-blocks-carousel>
    </dep-page-section-bpi>
  </ng-container>

  <!-- Section: podcasts -->
  <dep-page-section-bpi [sectionOverflow]="'right'" [sectionRouterLink]="actualitiesTypes[4].route"
    [sectionLinkLabel]="'Voir tous les podcasts'" [sectionLinkIcon]="actualitiesTypes[4].icon"
    *ngIf="actualitiesTypes[4] && podcasts.length > 0">
    <ng-container slot="title">
      <strong>{{actualitiesTypes[4].name}}</strong>
    </ng-container>

    <!-- Carousel of the last podcasts -->
    <dep-podcasts-carousel [podcasts]="podcasts"></dep-podcasts-carousel>
  </dep-page-section-bpi>

  <!-- Section: studies -->
  <dep-page-section-bpi [sectionRouterLink]="actualitiesTypes[5].route" [sectionLinkLabel]="'Voir plus d’études'"
    [sectionLinkIcon]="actualitiesTypes[5].icon" *ngIf="actualitiesTypes[5] && studies.length > 0">
    <ng-container slot="title">
      <strong>{{actualitiesTypes[5].name}}</strong>
    </ng-container>

    <!-- List of the last studies -->
    <dep-studies-list [responsiveDisplay]="'carousel'" [studies]="studies"></dep-studies-list>
  </dep-page-section-bpi>
  
</main>

<dep-main-footer></dep-main-footer>
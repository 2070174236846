import { UserInfo } from "../user-info/user-info.model"
import { HabilitationName } from "./habilitation.enum"

// export class User {
//     id: number
//     idMcb: string
//     email: string
//     organization:string
//     organizationType: string
//     userInfo: UserInfo
//     accessPartnerSpace: boolean
//     displayTango: boolean
//     displayTandem: boolean
//     accessObs: boolean
//     displayObs: boolean
//     displayCommunity: boolean
//     displayBftLab: boolean
// }


export class User {
    id: string
    realm: string
    firstName: string
    lastName: string
    email: string
    organization: Organization
    habilitations: Habilitation[]
    cguValidated: boolean
    firstConnection: boolean
    showContact: boolean
    status: UserStatus
    level:UserLevel


    hasAcceptedHabilitation(habilitation: string): boolean{
        return this.habilitations.find( hab => hab.habilitationStrapiCopy.name == habilitation && hab.status == StatusName.ACCEPTE) !=null;
    }

    hasHabilitation(habilitation: string): boolean{
        return this.habilitations.find( hab => hab.habilitationStrapiCopy.name == habilitation) !=null;
    }

}

export class Organization {
    name: string
    type: string
}

// export class Habilitation {
//     id: number
//     name: string
// }

// export class Status {
//     id: number
//     name: string
//     value: number
// }
// export class UserHabilitation {
//     id: number
//     habilitation: Habilitation
//     status: Status
// }


export class Habilitation {
    id: number
    habilitationStrapiCopy: HabilitationStrapiCopy
    status: StatusName
}

export class HabilitationStrapiCopy{
    name:string
}

// export enum HabilitationName {   
//     ACCESS_PARNTER_SPACE="access_partner_space",
//     DISPLAY_TANGO="display_tango",
//     DISPLAY_TANDEM="display_tandem",
//     ACCESS_OBS="access_obs",
//     DISPLAY_OBS="display_obs",
//     DISPLAY_COMMUNITY="display_community",
//     DISPLAY_BFT_LAB="display_bft_lab"
// }


export enum StatusName {   
    REFUSE="REFUSE",
    NON_DEMANDE="NON_DEMANDE",
    DEMANDE="DEMANDE",
    ACCEPTE="ACCEPTE"
}

export enum UserStatus {   
    NEW="NEW",
    MENMBER="MEMBER",
    PENDING="PENDING",
    PENDING_DELETION="PENDING_DELETION"
}

export enum UserLevel {
    ADMIN="ADMIN",
    SUPER_ADMIN="SUPER_ADMIN"
}
<dep-main-header></dep-main-header>

<main class="main">
  <!-- Fil d'ariane et titre -->
  <dep-page-header pageTitle="Monter une équipe fondatrice">
    <dep-breadcrumb [breadcrumbLabel]="'Fil d’ariane'" breadcrumb>
      <dep-breadcrumb-item [itemRouterLink]="'/'" *appBreadcrumbItem>Accueil</dep-breadcrumb-item>
      <dep-breadcrumb-item [itemRouterLink]="'/creer-sa-startup/.'" *appBreadcrumbItem>Envie d'entreprendre</dep-breadcrumb-item>
      <dep-breadcrumb-item *appBreadcrumbItem>Monter une équipe fondatrice</dep-breadcrumb-item>
    </dep-breadcrumb>
  </dep-page-header>

  <!-- La startup une aventure humaine avant tout -->
  <dep-page-section sectionTitle="La startup une aventure humaine avant tout" sectionTheme="medium">
    <dep-text>
      <p>
        La création d’une startup est avant tout une aventure humaine vécue par les fondateurs de l’entreprise et partagée avec ses partenaires, accompagnateurs, financeurs et clients.
      </p>
      <p>
        L’équilibre et la bonne entente de l’équipe fondatrice est un des principaux facteurs clés de succès d’une startup deeptech. <strong>Il est donc primordial de bien définir les rôles de chaque membre, chercheur et/ou entrepreneur, en appréciant à leur juste valeur les compétences de chacun (scientifique, management, business development, stratégie…).</strong>
      </p>
    </dep-text>
  </dep-page-section>
  <!-- FIN de La startup une aventure humaine avant tout -->

  <!-- Contenu éditorial -->
  <dep-page-section>
    <article class="article">
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h2>Constitution de l’équipe fondatrice</h2>

          <p>Que vous soyez chercheur ou (futur) entrepreneur, la question de l’équipe sera cruciale dans votre projet. C’est la capacité de votre équipe à réagir aux aléas inéluctables du marché ou de la technologie qui fait progresser votre projet. A ce titre, les investisseurs sont particulièrement vigilants sur la cohérence de l’équipe et du projet.</p>

          <p>La structure de valorisation joue le rôle de tierce partie dans la création de l’équipe fondatrice. Elle facilite les rencontres entre fondateurs potentiels, l’analyse des compétences clés de chacun, l’intégration des profils venant du monde de l’entreprise dont l’équipe a besoin et elle fixe un cadre pour tester la bonne collaboration des fondateurs avant leur association (période dite de « fiançailles »).</p>

          <p>Il est par ailleurs important que chaque membre de l’équipe ait une réflexion personnelle approfondie sur son parcours professionnel et le niveau de risque qu’il est prêt à accepter pour se positionner sur un rôle et en discuter avec l’organisme de valorisation. Cette réflexion pourra évoluer tout au long de la maturation du projet.</p>
        </div>
      </div>

      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h2>Le rôle du chercheur dans la startup</h2>

          <p>La nécessité d’avoir un chercheur impliqué dans le projet de maturation puis dans la future startup ne fait aucun doute. En effet, futurs clients et investisseurs voudront investiguer la science avant d’accorder leur confiance au produit (qui aura par ailleurs besoin d’évoluer dans le temps sur le plan technique).</p>

          <p>Si pour une personne issue du monde de l’entreprise le choix d’un engagement à 100% dans le projet de startup ne fait pas de doute, il existe 3 niveaux niveau d’engagement possibles pour les chercheurs fondateurs.</p>

          <p>À noter que si un chercheur ne souhaite pas être associé de l’entreprise, d’autres moyens de collaboration sont envisageables.</p>
        </div>

        <aside class="lg-grid-col-3 md-grid-col-4 sm-grid-col-3">
          <dep-resource resourceTitle="Évaluer mon rôle dans la startup" resource="/assets/docs/choisir-son-role.pdf" resourceAlignOffset="large">
            Une grille de questions pour guider le chercheur dans sa réflexion
          </dep-resource>
        </aside>
      </div>

      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <dep-card-full-width cardIcon="/assets/images/icones-groupe.svg" cardFullWidthTheme="medium">
            <h3>Chief Scientific Officer</h3>
            <p>
              Le conseiller scientique (CSO) est un chercheur qui souhaite poursuivre le développement scientifique de la technologie tout en gardant un pied dans son laboratoire.
            </p>
            <p>
              En restant employé du laboratoire, le chercheur limite son risque personnel et vit néanmoins l’aventure entrepreneuriale par son rôle de conseiller proche de l’équipe opérationnelle.
            </p>
          </dep-card-full-width>

          <dep-card-full-width cardIcon="/assets/images/icones-groupe.svg" cardFullWidthTheme="medium">
            <h3>Chief Executive Officer</h3>
            <p>
              Le Chief Executive Officer (CEO) est le directeur exécutif de la startup. C’est un poste à temps plein (jour et nuit) qui nécessite des compétences variées : compréhension de la technologie, vision stratégique et commerciale, management, gestion administrative.
            </p>
            <p>
              Il est fréquent d’intégrer un profil externe pour incarner ce poste clé dans la startup.
            </p>
          </dep-card-full-width>

          <dep-card-full-width cardIcon="/assets/images/icones-groupe.svg" cardFullWidthTheme="medium">
            <h3>Chief Technical Officer</h3>
            <p>
              Le Chief Technical Officer (CTO) est un membre actif de la startup à temps plein. Il est en charge de l’évolution des produits et services sur le plan technologique du stade de la preuve de concept jusqu’à l’industrialisation.
            </p>
            <p>
              Il est par ailleurs l’interface privilégiée entre la startup et les laboratoires de recherches partenaires.
            </p>
          </dep-card-full-width>
        </div>
      </div>

      <!-- Vidéos -->
      <div class="article__section grid-container">
        <div class="lg-grid-col-9 md-grid-col-8 sm-grid-col-4">
          <h2>Ils en parlent</h2>

          <div class="grid-container">
            <dep-video class="lg-grid-col-6 md-grid-col-4">
              <iframe title="Organisation de l'équipe | Boîte à outils #4" src="https://www.youtube.com/embed/2YBrR_d9LpY?autoplay=1&amp;amp;feature=oembed" width="100%" height="auto" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" name="fitvid0"></iframe>
            </dep-video>

            <dep-video class="lg-grid-col-6 md-grid-col-4">
              <iframe src="https://tv.bpifrance.fr/embed/4265/?" width="100%" height="auto" frameborder="0" webkitallowfullscreen="" allowfullscreen=""></iframe>
            </dep-video>
          </div>
        </div>
      </div>
      <!-- FIN de vidéos -->
    </article>
  </dep-page-section>
  <!-- FIN de Contenu éditorial -->

  <!-- Les parties prenantes clés -->
  <dep-page-section sectionTitle="Les parties prenantes clés" sectionTheme="medium">
    <div class="grid-container">
      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center" icon="/assets/images/groupe.svg">
        Les chercheurs
      </dep-icon-text>

      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center" icon="/assets/images/groupe.svg">
        Les établissements de recherche publique
      </dep-icon-text>

      <dep-icon-text class="lg-grid-col-3 md-grid-col-2 sm-grid-col-4" alignContent="center" icon="/assets/images/groupe.svg">
        La structure de valorisation
      </dep-icon-text>
    </div>
  </dep-page-section>
  <!-- FIN de Les parties prenantes clés -->

  <!-- Les autres composantes -->
  <dep-page-section sectionTitle="Les autres composantes">
    <div class="grid-container">

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/plan-de-financement.html']">Concevoir son business plan</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/acces-au-marche.html']">Dérisquage
            technico-économique du projet</a></dep-step>

      <dep-step class="lg-grid-col-3 md-grid-col-8 sm-grid-col-4"><a [routerLink]="['/creer-sa-startup/transfert-de-technologies.html']">Créer la startup et intégrer la PI</a></dep-step>
    </div>
  </dep-page-section>
  <!-- FIN de Les autres composantess -->
</main>

<dep-main-footer></dep-main-footer>

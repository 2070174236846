import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectComponent } from 'src/app/models/witness/selectComponent.model';

@Component({
  selector: 'dep-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.scss']
})
export class SelectMultipleComponent implements OnInit {
  @Input() options: SelectComponent[];
  @Input() label: string;
  @Input() placeholder: string;
  @Input() showNumbers: boolean = true;
  @Input() isSmallSelect : boolean = false;
  selectedOptions = [];
  isAllSelected = false;
  isOptionsOpen = false;
  @ViewChild('selectWrapper') selectWrapper!: ElementRef;

  @Output() selectedItems = new EventEmitter<any[]>();



  ngOnInit(): void {
  }

  ngOnChanges(){
    this.options = this.options.sort((a,b) => {return a.description.localeCompare(b.description);});
  }

  selectOption(option): void {
    if (this.isSelected(option)) {
      this.selectedOptions = this.selectedOptions.filter(o => o !== option);
    } else {
      this.selectedOptions.push(option);
    }
    this.updateAllSelectedStatus();
    this.applySelection();
  }

  toggleOptions(): void {
    this.isOptionsOpen = !this.isOptionsOpen;
  }

  toggleSelectAllOptions(): void {
    if (this.isAllSelected) {
      this.selectedOptions = [];
    } else {
      this.selectedOptions = [...this.options];
    }
    this.isAllSelected = !this.isAllSelected;

    this.applySelection();
  }

  resetSelection(): void {
    this.selectedOptions = [];
    this.isAllSelected = false;

    this.applySelection();
  }

  isSelected(option): boolean {
    return this.selectedOptions.includes(option);
  }

  updateAllSelectedStatus(): void {
    this.isAllSelected = this.selectedOptions.length === this.options.length;
  }

  getSelectedOptionsDisplay(): string {
    const maxLength = 20;
    let displayText = "";

    if (this.selectedOptions.length === 0) {
      displayText = this.placeholder;
    } else {
      displayText = this.selectedOptions.map(content => content.description).join(", ");
      if (displayText.length > maxLength) {
        displayText = displayText.substring(0, maxLength) + "...";
      }
    }
    return displayText;
  }

  closeOptions(): void {
    this.isOptionsOpen = false;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement) {
    const isInsideSelector = this.selectWrapper.nativeElement.contains(targetElement);

    if (!isInsideSelector) {
      this.closeOptions();
    }
  }

  applySelection() {
    this.selectedItems.emit(this.selectedOptions);
  }

}
